import { ICarouselImage } from '@dex/models/carousel-image.model';

type AdminBannersState = {
  images: ICarouselImage[];
  error: any;
  loading: boolean;
};

const initialAdminBannersState: AdminBannersState = {
  images: [],
  error: null,
  loading: false,
};

export { initialAdminBannersState };
export type { AdminBannersState };

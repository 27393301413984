/* libraries */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

/* state */
import LoginReducer from './login.reducer';
import ScrumBoardReducer from './scrum-board.reducer';
import NotificationReducer from './notification.reducer';
import EcommerceReducer from './ecommerce.reducer';

import { adminReducer } from '../slices/admin.slice';
import { authReducer } from '../slices/auth.slice';
import { divisionReducer } from '../slices/division.slice';
import { leagueReducer } from '../slices/league.slice';
import { upcomingEventsReducer } from '../slices/upcoming-events.slice';
import { searchReducer } from '../slices/search.slice';
import { LayoutReducer } from '../slices/layout.slice';
import { newsReducer } from '../slices/news.slice';
import { playerStatsReducer } from '../slices/player-stats.slice';
import { userReducer } from '../slices/user.slice';
import { adminBannersReducer } from '../slices/admin-banners.slice';

const rootReducer = combineReducers({
  login: LoginReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  // with slices
  admin: combineReducers({ root: adminReducer, banners: adminBannersReducer }),
  auth: authReducer,
  user: userReducer,
  layout: LayoutReducer,
  league: leagueReducer,
  news: newsReducer,
  division: divisionReducer,
  search: searchReducer,
  playerStats: playerStatsReducer,
  upcomingEvents: upcomingEventsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

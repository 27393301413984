import { INavigationItem } from './DexLayout/models/navigation-item.model';

export enum NavigationType {
  LEAGUE = 'league',
  LANDING = 'landing',
  LEAGUE_ADMIN = 'leagueAdmin',
}

const landingPageNavigation: INavigationItem[] = [
  {
    name: 'Services',
    description: 'Check out a list of services that Dex offers.',
    type: 'hash',
    icon: 'i-Bell',
    path: '/',
    hash: '#services',
  },
  {
    name: 'About',
    description: 'Some data about us and customer reviews.',
    type: 'hash',
    icon: 'i-Bar-Chart',
    path: '/',
    hash: '#about',
  },
  {
    name: 'Team',
    description: 'Lorem ipsum dolor sit.',
    type: 'hash',
    icon: 'i-Cool-Guy',
    path: '/',
    hash: '#team',
  },
  {
    name: 'How it Works',
    description: 'How do we do what we do?',
    type: 'hash',
    icon: 'i-Gears',
    path: '/',
    hash: '#how-it-works',
  },
  {
    name: 'Newsletter',
    description: 'Sign up to stay up to date with new info',
    type: 'hash',
    icon: 'i-Newspaper',
    path: '/',
    hash: '#newsletter',
  },
];

const leagueNavigation: INavigationItem[] = [
  {
    name: 'News',
    description: 'Pandamonium League News',
    type: 'route',
    icon: 'i-Newspaper',
    path: '/league/pandamonium-league/',
  },
  {
    name: 'Stats',
    description: 'Pandamonium League Dashboard',
    type: 'dropdown',
    icon: 'i-Statistic',
    path: '/league/pandamonium-league/stats',
    sub: [
      {
        name: 'General Stats',
        description: 'General League Stats',
        type: 'route',
        icon: 'i-Controller',
        path: '/league/pandamonium-league/stats',
      },
      {
        name: 'Player Stats',
        description: 'League Player Stats',
        type: 'route',
        icon: 'i-Controller',
        path: '/league/pandamonium-league/stats/players',
      },
      {
        name: 'League Insights',
        description: 'League Player Stats',
        type: 'route',
        icon: 'i-Controller',
        path: '/league/pandamonium-league/stats/insights',
      },
    ],
  },
  {
    name: 'Info',
    description: 'League Info',
    type: 'route',
    icon: 'i-Idea-2',
    path: '/league/pandamonium-league/info',
  },
  {
    name: 'Rules',
    description: 'Tournament Rules',
    type: 'route',
    icon: 'i-Book',
    path: '/league/pandamonium-league/rules',
  },
  {
    name: 'Schedule',
    description: 'League Schedule',
    type: 'dropdown',
    icon: 'i-Calendar-3',
    path: '/league/pandamonium-league/schedule',
    sub: [
      {
        icon: 'i-Split-Horizontal-2-Window',
        description: 'League of Legends Schedule',
        name: 'League of Legends',
        path: '/league/pandamonium-league/schedule/1',
        type: 'route',
      },
      {
        icon: 'i-Medal-2',
        description: 'Valorant Schedule',
        name: 'Valorant',
        path: '/league/pandamonium-league/schedule/2',
        type: 'route',
      },
    ],
  },
  {
    name: 'Social',
    description: 'League Social Media',
    type: 'dropdown',
    icon: 'i-Monitor-3',
    path: '/league/pandamonium-league/social',
    sub: [
      {
        name: 'Facebook',
        description: 'Panda Gaming League Facebook',
        type: 'extLink',
        icon: 'i-Facebook',
        path: 'https://www.facebook.com/pandagaming/',
      },
      {
        name: 'Twitch',
        description: 'Panda Gaming League Twitch',
        type: 'extLink',
        icon: 'i-Twitch',
        path: 'https://www.twitch.tv/pandagaming/',
      },
      {
        name: 'Twitter',
        description: 'Panda Gaming League Twitter',
        type: 'extLink',
        icon: 'i-Twitter',
        path: 'https://www.twitter.com/pandagaming/',
      },
      {
        name: 'Instagram',
        description: 'Panda Gaming League Intagram',
        type: 'extLink',
        icon: 'i-Instagram',
        path: 'https://www.instagram.com/pandagaming/',
      },
      {
        name: 'YouTube',
        description: 'Panda Gaming League YouTube',
        type: 'extLink',
        icon: 'i-Youtube',
        path: 'https://www.youtube.com/pandagaming/',
      },
    ],
  },
];

const leagueAdminNavigation: INavigationItem[] = [
  {
    name: 'Dashboard',
    description: 'Pandamonium Administration Dasbhoard',
    type: 'route',
    icon: 'i-Dashboard',
    path: '/admin/league/pandamonium-league/',
  },

  {
    name: 'Divisions',
    description: 'Add, Remove, or Modifiy Divisions & Tournaments',
    type: 'route',
    icon: 'i-Geek',
    path: '/admin/league/pandamonium-league/divisions',
    // sub: [
    //   {
    //     name: 'Apex Legends',
    //     description: 'Pandamonium Apex Legends Division Administration',
    //     type: 'route',
    //     path: '/admin/league/pandamonium-league/divisions/11111',
    //   },
    //   {
    //     name: 'Call of Duty',
    //     description: 'Pandamonium Call of Duty Division Administration',
    //     type: 'route',
    //     path: '/admin/league/pandamonium-league/divisions/22222',
    //   },
    //   {
    //     name: 'League of Legends',
    //     description: 'Pandamonium League of Legends Division Administration',
    //     type: 'route',
    //     path: '/admin/league/pandamonium-league/divisions/33333',
    //   },
    //   {
    //     name: 'Rocket League',
    //     description: 'Pandamonium Call of Duty Division Administration',
    //     type: 'route',
    //     path: '/admin/league/pandamonium-league/divisions/44444',
    //   },
    //   {
    //     name: 'Overwatch 2',
    //     description: 'Pandamonium Overwatch 2 Division Administration',
    //     type: 'route',
    //     path: '/admin/league/pandamonium-league/divisions/55555',
    //   },
    //   {
    //     name: 'Valorant',
    //     description: 'Pandamonium Valorant Division Administration',
    //     type: 'route',
    //     path: '/admin/league/pandamonium-league/divisions/66666',
    //   },
    // ],
  },
  {
    name: 'Billing',
    description: 'League Billing Administration',
    type: 'route',
    icon: 'i-Billing',
    path: 'admin/league/pandamonium-league/billing',
  },
  {
    name: 'Scheduling',
    description: 'League Schedule',
    type: 'route',
    icon: 'i-Calendar-3',
    path: '/admin/league/pandamonium-league/schedule',
  },
  {
    name: 'Info',
    description: 'League Info',
    type: 'route',
    icon: 'i-Idea-2',
    path: 'admin/league/pandamonium-league/info',
  },
  {
    name: 'Tools',
    description: 'League Administration Tools',
    type: 'dropdown',
    icon: 'i-Gears',
    path: '/admin/league/pandamonium-league/tools/',
    sub: [
      {
        name: 'Cost Calculator',
        description: 'General League Stats',
        type: 'route',
        icon: 'i-Money',
        path: '/admin/league/pandamonium-league/tools/cost-calculator',
      },
    ],
  },
];

export const getNavigation = (
  type: NavigationType,
  divisionId?: string
): INavigationItem[] => {
  switch (type) {
    case NavigationType.LEAGUE:
      return leagueNavigation;
    case NavigationType.LEAGUE_ADMIN:
      return leagueAdminNavigation;
    default:
      return landingPageNavigation;
  }
};

import { createSlice } from '@reduxjs/toolkit';
import { initialAdminBannersState } from '../state/admin-banners.state';
import { fetchImages } from 'app/views/app/league/administration/divisions/banners/admin-banners.service';

export const adminBannersSlice = createSlice({
  name: 'adminBanners',
  initialState: initialAdminBannersState,
  reducers: {
    setAdminBanners: (state, action) => {
      state.images = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchImages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchImages.fulfilled, (state, action) => {
      state.loading = false;
      state.images = action.payload;
      state.error = null;
    });
    builder.addCase(fetchImages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { actions: adminBannersActions, reducer: adminBannersReducer } =
  adminBannersSlice;
export const { setAdminBanners } = adminBannersActions;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ICarouselImage } from '@dex/models/carousel-image.model';

const baseUrl = process.env.REACT_APP_BFF_BASE_URL || 'https://bff.dexesports.gg';

export const fetchImages = createAsyncThunk(
  'admin/banners/fetchImages',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
  }: {
    organizationId: string;
    divisionId?: string;
    subDivisionId?: string;
  }) => {
    const url = `${baseUrl}/news/banners`;

    const response = await axios.get(url, {
      params: {
        organizationId,
        ...(divisionId && { divisionId }),
        ...(subDivisionId && { subDivisionId }),
      },
    });
    if (response.status !== 200) {
      throw new Error('Error fetching images');
    }

    return response.data as ICarouselImage[];
  }
);

const useFetchImages = (
  organizationId: string,
  divisionId?: string,
  subDivisionId?: string
) => {
  const dispatch: any = useDispatch<AppDispatch>();
  const images = useSelector((state: RootState) => state.admin.banners.images) || [];
  const loading = useSelector((state: RootState) => state.admin.banners.loading) || false;
  const error = useSelector((state: RootState) => state.admin.banners.error) || null;

  useEffect(() => {
    dispatch(fetchImages({ organizationId, divisionId, subDivisionId }));
  }, [dispatch, organizationId, divisionId, subDivisionId]);

  return { images, loading, error };
};

export { useFetchImages };
